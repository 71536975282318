import React from "react"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import Footer from "../components/Footer"
import Brands from "../components/Brands"
import Services from "../components/Services"
import Testimonials from "../components/Testimonials"
import Plan from "../components/Plan"
import ContentImage from "../components/ContentImage"
import SampleWorksSwiper from "../components/SampleWorksSwiper"

// const logo = [
//   {
//     name: 'Pearls of Joy',
//     image: require('../assets/svg/brands-pearls-of-joy.svg')
//   }, {
//     name: 'Sister & Soul',
//     image: require('../assets/svg/brands-sister-and-soul.svg')
//   }, {
//     name: 'Pure Pearls',
//     image: require('../assets/svg/brands-pure-pearls.svg')
//   }, {
//     name: 'Spinaleze',
//     image: require('../assets/svg/brands-spinaleze.svg')
//   }, {
//     name: 'Madeira',
//     image: require('../assets/images/brands-madeira.png')
//   }
// ]

const logoFeat = [
  {
    name: 'Gatsby',
    image: require('../assets/svg/gatsby-1.svg'),
    link: 'https://www.gatsbyjs.org/showcase/fullstackhq.com',
    activeLink: true
  }, {
    name: 'Awards',
    image: require('../assets/svg/brands-awwwards-logo.svg'),
    link: 'https://www.awwwards.com/sites/psd-wizard-1',
    activeLink: true
  }, {
    name: 'Css Design Awards',
    image: require('../assets/svg/brands-cssdesign-logo.svg'),
    link: 'https://www.cssdesignawards.com/sites/psd-wizard/32529/',
    activeLink: true
  }, {
    name: 'JAMStack',
    image: require('../assets/svg/brands-jamstack-logo.svg'),
    link: 'https://jamstack.org/examples/',
    activeLink: true
  }, {
    name: 'React Static',
    image: require('../assets/svg/brands-react-logo.svg'),
    link: 'https://github.com/react-static/react-static#sites-built-with-react-static',
    activeLink: true
  }
]

const servicesInfo = [
  {
    image: require('../assets/images/services-img7.png'),
    desc: 'Fix problems fast'
  }, {
    image: require('../assets/images/services-img8.png'),
    desc: 'Theme layout tweaks and configurations to save you a ton of time'
  }, {
    image: require('../assets/images/services-img9.png'),
    desc: 'Speed optimization to boost conversion and Google ranking'
  }, {
    image: require('../assets/images/services-img10.png'),
    desc: 'Small on-page SEO fixes like adding meta tags'
  }, {
    image: require('../assets/images/services-img11.png'),
    desc: 'Quick Shopify app configuration'
  }, {
    image: require('../assets/images/services-img12.png'),
    desc: 'Shopify app recommendations'
  }, {
    image: require('../assets/images/services-img13.png'),
    desc: 'Ongoing maintenance (theme and plugin updates, cleanup)'
  }, {
    image: require('../assets/images/services-img14.png'),
    desc: 'Consultation on web development and other related matters'
  }, {
    image: require('../assets/images/services-img15.png'),
    desc: 'Monthly report of work performed'
  }
]

const plansInfo = [
  {
    image: require('../assets/images/single-task.png'),
    title: 'Maintenance',
    subtitle: 'Give it a try!',
    price: '400/mo',
    type: 'perTask',
    desc: [
      {
        listInfo: '20-hour credit balance',
        description:
        <p>
         You will be given 20-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
	     {
        listInfo: 'Updating of graphics, text, and code',
      },
	  {
        listInfo: 'Page speed optimization',
      },
	  {
        listInfo: 'Minor bug fixes',
      },  {
        listInfo: 'Covers one store',
		  description:
        <p>
         Your maintenance subscription and the credits you receive monthly, covers one Shopify store.
        </p>
      }, 
	  {
        listInfo: 'Monthly report',
		  description:
        <p>
         Monthly report of work performed and a summary for each service.
        </p>
      },
    ],
    buttonText: 'Buy 20 hours',
	variant: 'outline',
    buttonLink: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-7TU50290J52525636ML2LTTA',
    buttonBeacon: false,
    buttonExternal: true,
    buttonNextTab: false,
  },
  {
    image: require('../assets/images/unlimited-task.png'),
    title: 'Full Stack',
    subtitle: 'Subscription',
    price: '720',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: 'from 40 hours credit balance',
        description:
        <p>
         Our Pay As You Go plans are considered prepaid blocks of hours. If you do not use all your hours in one month, they are rolled over to the next month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
     {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Learn more',
    variant: 'normal',
    buttonLink: '/development',
    buttonBeacon: false,
    buttonExternal: false,
    buttonNextTab: false,
  }
]

const testimonyList = [
  {
    text: `Wow @peng & @pamela - this is really good stuff! You worked really fast and these pages look great. Very good work guys! Keep it coming`,
    image: require('../assets/images/kevin-kaninng-lg.png'),
    name: 'Kevin Canning',
    position: 'Founder, Pearls of Joy'
  }, {
    text: `Thank YOU Pamela - the site looks AMAZING so far! I know we're all working feverishly to get last minute touches and fix bugs, but wow I am pleased!`,
    image: require('../assets/images/ashley-mcnamara.png'),
    name: 'Ashley McNamara',
    position: 'CEO, Purepearls.com'
  }, {
    text: `First of all, I just want to say a big thank you for the quality and efficiency of your team's work. Pamela has been really amazing and prompt with all projects and tasks!`,
    image: require('../assets/images/renata-reis.png'),
    name: 'Renata Reis',
    position: 'collective.care, Marketing Manager'
  }
]

const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: true,
  title: 'Work with a battle-tested Shopify Expert',
  desc: `Focus on growing your business. Leave all your development needs to Fullstack HQ.`,
  image: require('../assets/images/mascot-shopify.png'),
  hasImage: true,
  logoImage: require('../assets/svg/shopify-partners.svg'),
  logoName: 'Shopify',
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

const worksSwiperContent = [
  {
    comment: "Thank YOU Pamela - the site looks AMAZING so far! I know we're all working feverishly to get last minute touches and fix bugs, but wow I am pleased!",
    long: false,
    slideItem: 'slideOne',
    avatar: require('../assets/images/ashley-mcnamara.png'),
    name: "Ashley McNamara",
    position: "CEO, Pure Pearls",
    projectImage: require('../assets/images/sample-projects-9.png'),
    link: 'https://www.purepearls.com/',
    external: true
  },
  {
    comment: "Wow @peng & @pamela - this is really good stuff! You worked really fast and these pages look great. Very good work guys! Keep it coming",
    long: false,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/kevin-kaninng.png'),
    name: "Kevin Canning",
    position: "Founder, Pearls of Joy",
    projectImage: require('../assets/images/sample-projects-11.png'),
    link: 'https://www.pearlsofjoy.com/',
    external: true
  },
  {
    comment: "Mo from Spinaleze likes the work you guys did. Could you kindly liaise with Mo directly, allow him to buy a block of hours from you and Mo has some other tasks for the team as well.",
    long: true,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/liam-obrien.png'),
    name: "Liam OBrien",
    position: "Co-Founder, BidPixel",
    projectImage: require('../assets/images/sample-projects-12.png'),
    link: 'https://www.spinaleze.com.au/',
    external: true
  },
  {
    comment: "Looks really good Peng! Nice work and congrats. Love your work Peng. Really happy to refer Fullstack whenever I have the opprtunity.",
    long: true,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/matt-joseph.png'),
    name: "Matt Joseph",
    position: "Founder, Rawmate",
    projectImage: require('../assets/images/sample-projects-20.png'),
    link: 'https://www.rawmate.com/',
    external: true
  },
  {
    comment: "It looks great, if that’s what you recommend I’m sure you’ll be able to do a beautiful job with that.",
    long: false,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/julie-grant.png'),
    name: "Julie Grant",
    position: "Creator, Mandala Dream Co.",
    projectImage: require('../assets/images/sample-projects-13.png'),
    link: 'https://www.mandaladreamco.com.au/',
    external: true
  }
]

function Shopify() {
  return (
    <>
      <OgTags
        ogTitle="Outsource Shopify Developer help by Fullstack HQ"
        ogDescription="Improve your site’s functionality and rest easy with Fullstack HQ&#39;s Shopify Premium Support."
        ogUrl="https://fullstackhq.com/shopify-help"
      />
      <Header />
      <main className="maintenance-landing initialized-header">
        <ContentImage
          content={cta}
        />
        <Testimonials
          title="Loved by world-class entrepreneurs & tech companies"
          testimony={testimonyList}
        />
        <Brands
          text="We've been featured on"
          brands={logoFeat}
        />
        <Services
          heading="What we do"
          services={servicesInfo}
        />
        <SampleWorksSwiper
          heading="Selected sample works"
          worksSwiper ={worksSwiperContent}
        />
        <Plan
          heading="Simple pricing for your Shopify needs"
          content={plansInfo}
        />
      </main>
      <Footer />
    </>
  )
}

export default Shopify
